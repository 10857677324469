import 'firebase/functions';

import React from 'react';
import styled from 'styled-components';

import EQText from '../components/EQText';
import useRequiresVoucherCode from '../hooks/useRequiresVoucherCode';

const Container = styled.div`
  padding: ${({ theme }) => theme.space.M};
  margin: 10% 20px;
  border-radius: ${({ theme }) => theme.space.S};
  background-color: ${({ theme }) => theme.colors.silver};
`;

const Headline = styled(EQText)`
  ${({ theme }) => theme.typography.MediumHeadline};
  margin-top: ${({ theme }) => theme.space.M};
  margin-bottom: ${({ theme }) => theme.space.S};
`;

const NotFound = () => {
  useRequiresVoucherCode();
  return (
    <Container>
      <Headline>404 - page not found</Headline>
    </Container>
  );
};

export default NotFound;
