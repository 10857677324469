import firebase from 'firebase/app';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { VoucherContext } from '../App';
import EQButton from '../components/EQButton';
import EQCheckbox from '../components/EQCheckbox';
import EQText from '../components/EQText';
import EQTextInput from '../components/EQTextInput';
import Span from '../components/Span';
import Wrapper from '../components/Wrapper';
import { ReactComponent as ExclamationMark } from '../icons/ExclamationMark.svg';
import Colors from '../theme/Colors';
import { ON_DESKTOP, ON_PHONE } from '../utils/media';
import { emailRegExp, passwordRegExp } from '../utils/regex';
import { backendErrors } from '../utils/types';

interface LoginFormData {
  firstName: string;
  email: string;
  password: string;
  privacyService: any;
}

const Container = styled.div`
  padding: ${({ theme }) => theme.space.L};
  border-radius: ${({ theme }) => theme.space.S};
  background-color: ${({ theme }) => theme.colors.silver};
  max-width: 640px;

  ${ON_DESKTOP} {
    width: 100%;
  }

  & a {
    font-weight: bold;
  }
`;

const Headline = styled(EQText)`
  ${({ theme }) => theme.typography.MediumHeadline};
  margin-top: ${({ theme }) => theme.space.M};
  margin-bottom: ${({ theme }) => theme.space.S};
`;

const ErrorMessage = styled.div<{ hide?: boolean }>`
  margin-horizontal: ${({ theme }) => theme.space.XXXS};
  margin-top: ${({ theme }) => theme.space.XS};
  color: ${({ theme }) => theme.colors.error};
  font-family: ${({ theme }) => theme.fonts.OPEN_SANS_REGULAR};
  font-size: ${({ theme }) => theme.fontSizes.S};
  align-self: flex-start;

  ${(props) => props.hide && 'opacity:0; user-select:none; cursor:cursor;'}
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${ON_PHONE} {
    align-items: flex-start;
  }
`;

const Register = () => {
  const { errors, register, handleSubmit, setError, formState } = useForm({
    mode: 'onSubmit',
  });
  const [checked, setChecked] = useState<boolean>(false);
  const { voucher } = useContext(VoucherContext);
  const history = useHistory();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setChecked(e.target.checked);
  };

  const redeemVoucherCode = useMemo(
    () =>
      firebase
        .app()
        .functions('europe-west1')
        .httpsCallable('redeemVoucherCode'),
    [],
  );

  const onSubmit = async (data: LoginFormData) => {
    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password);

      const response = await redeemVoucherCode({ code: voucher.code });
      if (response.data.error) {
        setError('code', {
          type: 'manual',
          message: 'This code is not valid.',
        });
      } else {
        history.push('/activated');
      }
    } catch (error) {
      const e = backendErrors[error.code];
      setError(e[0], { type: 'manual', message: e[1] });
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EQText>
          To activate your {voucher.typeString} subscription, please log in or
          create a new Equalista account so that we know which account we need
          to link to the subscription. Of course, this subscription will not
          renew automatically.
        </EQText>
        <Headline>Sign up</Headline>
        <EQText color={'darkGrey'} fontSize={'S'} pb="M">
          Already have an account?
          <Span pl={'XS'}>
            <a href="/#/login/" style={{ color: Colors.eqBlue }}>
              LOG IN
            </a>
          </Span>
        </EQText>
        <EQTextInput
          error={errors.firstName?.message}
          name="firstName"
          onBlur={() => emailRef.current?.focus()}
          placeholder="First name"
          ref={register({
            required: 'Oops, please enter a valid name.',
          })}
          type="text"
        />
        <EQTextInput
          error={errors.email?.message}
          name="email"
          onBlur={() => passwordRef.current?.focus()}
          placeholder="Email"
          ref={(el) => {
            register(el, {
              pattern: {
                value: emailRegExp,
                message: 'Oops, please enter a valid email address.',
              },
            });
            // @ts-ignore
            emailRef.current = el;
          }}
          type="text"
        />
        <EQTextInput
          error={errors.password?.message}
          name="password"
          placeholder="Password (8+ characters)"
          ref={(el) => {
            register(el, {
              pattern: {
                value: passwordRegExp,
                message: 'Oops, please enter at least 8 characters.',
              },
            });
            // @ts-ignore
            passwordRef.current = el;
          }}
          type="password"
        />
        <Wrapper mb={'L'}>
          <CheckboxWrapper>
            <Wrapper height={24} margin={4} width={18}>
              <ExclamationMark
                height={'24px'}
                visibility={errors.privacyService ? 'visible' : 'hidden'}
                width={'24px'}
              />
            </Wrapper>
            <label>
              <EQCheckbox
                checked={checked}
                name="privacyService"
                onChange={handleCheckboxChange}
                ref={register({
                  required:
                    'Oops, please check and accept our Terms of Service and Privacy Policy.',
                })}
                value="privacyServiceCheck"
              />
            </label>

            <EQText
              color={Colors.darkGrey}
              fontSize={'S'}
              fontWeight="light"
              pl={'S'}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              I've read and agree to Equalista's{' '}
              <a
                href="https://equalista.com/terms-conditions/ "
                rel="noreferrer"
                style={{ color: Colors.eqBlue }}
                target="_blank">
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href="https://equalista.com/data-privacy-policy/"
                rel="noreferrer"
                style={{ color: Colors.eqBlue }}
                target="_blank">
                Privacy Policy
              </a>
            </EQText>
          </CheckboxWrapper>
          {errors.privacyService ? (
            <ErrorMessage>{errors.privacyService?.message}</ErrorMessage>
          ) : (
            <ErrorMessage hide>no error</ErrorMessage>
          )}
        </Wrapper>
        <EQButton color="eqGreen" isLoading={formState.isSubmitting}>
          Sign up
        </EQButton>
      </form>
    </Container>
  );
};

export default Register;
