import React, { useState } from 'react';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

import EQText from './components/EQText';
import Wrapper from './components/Wrapper';
import logo from './images/EQLogo.svg';
import ActivatedCode from './pages/Activated';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import RedeemCode from './pages/Redeem';
import Register from './pages/Register';
import ResetPassword from './pages/Reset';
import EqualistaTheme from './theme';
import { ON_DESKTOP, ON_PHONE } from './utils/media';
import { Voucher } from './utils/types';

const Page = styled.div<{ hasVoucher: boolean }>`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${ON_DESKTOP} {
    margin: ${({ theme }) => theme.space.M};
    margin-top: 10vh;
    min-height: 90vh;
  }

  ${ON_PHONE} {
    background-color: ${({ theme, hasVoucher }) =>
      hasVoucher && theme.colors.card};
  }

  & a {
    color: ${({ theme }) => theme.colors.eqBlue};
  }
`;

const StyledLogo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  margin: ${({ theme }) => theme.space.XL};

  ${ON_PHONE} {
    display: none;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface VoucherContextInterface {
  voucher: Voucher;
  // eslint-disable-next-line no-unused-vars
  setVoucher: (v: Voucher) => void;
}

export const VoucherContext = React.createContext<VoucherContextInterface>({
  voucher: {
    type: undefined,
    code: '',
    typeString: undefined,
  },
  setVoucher: () => {},
});

function App() {
  const [voucher, setVoucher] = useState<Voucher>({
    type: undefined,
    code: '',
  });
  return (
    <ThemeProvider theme={EqualistaTheme}>
      <VoucherContext.Provider value={{ voucher, setVoucher }}>
        <Router>
          <Page hasVoucher={!!voucher.code}>
            <StyledLogo src={logo} />
            <Content>
              <Switch>
                <Route component={RedeemCode} exact path="/" />
                {voucher.code && (
                  <>
                    <Route component={Register} path="/register" />
                    <Route component={Login} path="/login" />
                    <Route component={ActivatedCode} path="/activated" />
                    <Route component={ResetPassword} path="/resetPassword" />
                  </>
                )}
                <Route component={NotFound} path="*" />
              </Switch>
            </Content>
            <Wrapper pb={'S'}>
              <EQText fontSize="XS" textAlign="center">
                © {new Date().getFullYear()} Equalista GmbH
              </EQText>
            </Wrapper>
          </Page>
        </Router>
      </VoucherContext.Provider>
    </ThemeProvider>
  );
}

export default App;
