import { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { VoucherContext } from "../App"

const useRequiresVoucherCode = () => {
    const history = useHistory()
    const { voucher } = useContext(VoucherContext)

    useEffect(() => {
        if (!voucher.code) {
            history.push("/")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

export default useRequiresVoucherCode;
