import React from "react";
import styled from "styled-components";
import Colors from "../theme/Colors";

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
interface PropsStyledCheckbox {
  checked: boolean;
}

const Icon = styled.svg`
  fill: none;
  stroke: #000c36;
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div<PropsStyledCheckbox>`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 4px;
  background: white;
  border-radius: 3px;
  border: solid #000c36 3px;
  transition: all 150ms;
  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export interface EQCheckboxProps {
  checked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
}

const EQCheckbox = React.forwardRef(
  (props: EQCheckboxProps, ref: React.Ref<HTMLInputElement>) => {
    return (
      <CheckboxContainer className="className">
        <HiddenCheckbox {...props} ref={ref} />
        <StyledCheckbox {...props}>
          <Icon viewBox="0 0 24 24">
            <polyline stroke={Colors.eqGreen} points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
    );
  }
);

export default EQCheckbox;
