import React, { useContext } from 'react';
import styled from 'styled-components';

import { VoucherContext } from '../App';
import EQText from '../components/EQText';
import appStoreImg from '../icons/AppStore.png';
import playStoreImg from '../icons/PlayStore.png';
import activatedIllustration from '../images/ActivatedIllustration.svg';
import { ON_DESKTOP } from '../utils/media';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WrapperCustom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
`;

const StoreImage = styled.img`
  height: 40px;
  @media (min-height: 900px) {
    height: 48px;
  }
`;

const Container = styled.div`
  padding: ${({ theme }) => theme.space.L};
  border-radius: ${({ theme }) => theme.space.S};
  background-color: ${({ theme }) => theme.colors.silver};
  max-width: 640px;

  ${ON_DESKTOP} {
    width: 100%;
  }
`;

const StyledIllustration = styled.img`
  max-width: 100%;
`;

const ActivatedCode = () => {
  const { voucher } = useContext(VoucherContext);
  return (
    <OuterContainer>
      <Container>
        <EQText fontSize={'L'} fontWeight="600" paddingBottom={20}>
          Your subscription has been activated
        </EQText>
        <EQText fontSize={'M'} paddingBottom={'S'}>
          Your {voucher.typeString} subscription has now been activated and can
          be used in the mobile Equalista app. If you haven’t already, download
          our app on the Apple App Store or on the Google Play Store. {'\n\n'}
          See you in the App!
        </EQText>
        <EQText fontSize="M" paddingBottom={'L'}>
          See you in the App!
        </EQText>
        <WrapperCustom>
          <a
            href="https://apps.apple.com/ng/app/equalista-gender-equality/id1517567477"
            rel="noreferrer"
            target="_blank">
            <StoreImage alt="app-store-logo" src={appStoreImg} />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.equalista&hl=en&gl=US"
            rel="noreferrer"
            target="_blank">
            <StoreImage alt="play-store-logo" src={playStoreImg} />
          </a>
        </WrapperCustom>
      </Container>
      <StyledIllustration src={activatedIllustration} />
    </OuterContainer>
  );
};

export default ActivatedCode;
