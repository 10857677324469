import React, { ReactNode } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';
import { SpaceProps, WidthProps, variant, width } from 'styled-system';

import Wrapper from './Wrapper';

/**
 * Types & Interfaces
 */

type ButtonSize = 'mini' | 'small' | 'medium';
type ButtonAppearance = 'filled' | 'ghost';
type ButtonStatus = 'primary' | 'secondary' | 'tertiary';
type ButtonColor = 'brand' | 'eqGreen' | 'eqBlue' | 'lightGrey' | 'eqBerry';
interface EQButtonProps extends SpaceProps, WidthProps {
  appearance?: ButtonAppearance;
  children?: ReactNode;
  color?: ButtonColor;
  icon?: React.ReactNode;
  isLoading?: boolean;
  size?: ButtonSize;
  status?: ButtonStatus;
  disabled?: boolean;
  style?: string;
  placeholder?: string;
  link?: string;
}
interface ButtonViewProps
  extends Pick<
    EQButtonProps,
    | 'size'
    | 'color'
    | 'appearance'
    | 'status'
    | 'disabled'
    | 'isLoading'
    | 'width'
  > {
  pressed?: boolean;
}

/**
 * Styled Components
 */

const Button = styled.button<ButtonViewProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  justify-content: center;
  cursor: pointer;
  border: none;

  outline: none;
  &:active {
    opacity: 0.6;
    transform: translate(0, 4px);
  }

  ${width}

  ${variant({
    prop: 'size',
    variants: {
      mini: {
        px: 'none',
        py: 'none',
      },
      small: {
        px: 'S',
        py: 'S',
      },
      medium: {
        px: 'M',
        py: 'M',
      },
    },
  })}

    ${(props) =>
    variant({
      prop: 'status',
      variants: {
        primary: {
          bg: props.color,
          borderRadius: '28px',
        },
        secondary: {
          borderWidth: '2px',
          borderRadius: '28px',
          borderColor: props.color,
        },
        tertiary: {
          bg: 'transparent',
        },
      },
    })}

  ${variant({
    prop: 'appearance',
    variants: {
      filled: {},
      ghost: {
        bg: 'transparent',
      },
    },
  })}


  ${(props) =>
    props.disabled && !props.isLoading && props.appearance !== 'ghost'
      ? `background-color:${props.theme.colors.disabledButtonBackground}`
      : ''}

  ${(props) =>
    props.isLoading &&
    `opacity:0.6;
    transform: translate(0,4px);`}

  ${(props) => (props.disabled || props.isLoading) && ' pointer-events: none;'}
`;

const Label = styled.div<{
  appearance: ButtonAppearance;
  status: ButtonStatus;
  size: ButtonSize;
}>`
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  font-style: normal;
  font-weight: bold;

  ${variant({
    prop: 'appearance',
    variants: {
      filled: {
        fontFamily: 'OPEN_SANS_REGULAR',
        color: 'eqWhite',
      },
      ghost: {
        fontFamily: 'OPEN_SANS_REGULAR',
        color: 'brand',
      },
    },
  })}

  ${variant({
    prop: 'status',
    variants: {
      primary: {
        letterSpacing: '1.25px',
      },
      secondary: {
        color: 'brand',
      },
      tertiary: {
        textDecoration: 'underline',
      },
    },
  })}

  ${variant({
    prop: 'size',
    variants: {
      mini: {
        fontSize: 'XS',
      },
      small: {
        fontSize: 'S',
      },
      medium: {
        fontSize: 'M',
      },
    },
  })}
`;

const EQButton = ({
  status = 'primary',
  appearance = 'filled',
  color = 'brand',
  size = 'medium',
  isLoading = false,
  children = '',
  disabled = false,
  width = 1,
}: EQButtonProps) => {
  return (
    <Button
      appearance={appearance}
      color={color}
      disabled={isLoading || disabled}
      isLoading={isLoading}
      size={size}
      status={status}
      width={width}>
      {isLoading ? (
        <Wrapper
          alignItems="center"
          display="flex"
          height={22}
          justifyContent="center">
          <BeatLoader color={'#FFFFFF77'} size={10} />
        </Wrapper>
      ) : (
        <Label appearance={appearance} size={size} status={status}>
          {children}
        </Label>
      )}
    </Button>
  );
};

export default React.memo(EQButton);
