const Fonts = {
    LELO_MEDIUM: 'Lelo-Medium',
    OPEN_SANS_REGULAR:  "Open Sans",
    OPEN_SANS_SEMIBOLD: 'OpenSans SemiBold',
    OPEN_SANS_BOLD: 'OpenSans Bold',
    medium: 'Lelo-Medium',
    'semi-bold': 'OpenSans-SemiBold',
    regular: 'OpenSans-Regular',
    bold: 'OpenSans-Bold',
  };

  export default Fonts;
