import Colors from './Colors';
import Fonts from './Fonts';


const FontSizesValues = {
  XXS: 10,
  XS: 12,
  S: 14,
  M: 16,
  L: 20,
  XL: 24,
  XXL: 30,
};

const SpaceValues = {
  none: 0,
  XXXS: 4,
  XXS: 6,
  XS: 8,
  S: 12,
  M: 16,
  L: 24,
  XL: 32,
  XXL: 40,
  XXXL: 48,
};


const FontSizes = {
  XXS: '10px',
  XS: '12px',
  S: '14px',
  M: '16px',
  L: '20px',
  XL: '24px',
  XXL: '30px',
};

const Space = {
  none: '0px',
  XXXS: '4px',
  XXS: '6px',
  XS: '8px',
  S: '12px',
  M: '16px',
  L: '24px',
  XL: '32px',
  XXL: '40px',
  XXXL: '48px',
};

const Catchphrase = {
  fontSize: FontSizesValues.XXL,
  fontFamily: Fonts.LELO_MEDIUM,
  color: Colors.brand,
};

// Headline 1
const Headline = {
  fontSize: FontSizesValues.XXL,
  fontFamily: Fonts.LELO_MEDIUM,
  color: Colors.brand,
};

// Headline 2
const MediumHeadline = {
  fontSize: FontSizesValues.XL,
  fontFamily: Fonts.LELO_MEDIUM,
  color: Colors.brand,
};

// Headline 3
const CardHeader = {
  fontSize: FontSizesValues.L,
  fontFamily: Fonts.LELO_MEDIUM,
  color: Colors.brand,
};

// P1)
const BodyText = {
  fontFamily: Fonts.OPEN_SANS_REGULAR,
  fontSize: FontSizesValues.M,
  color: Colors.brand,
};

// P2 grey
const AdditionalInformation = {
  fontFamily: Fonts.OPEN_SANS_REGULAR,
  fontSize: FontSizesValues.S,
  color: Colors.neutralText,
};

// Link
const Link = {
  fontFamily: Fonts.OPEN_SANS_SEMIBOLD,
  fontSize: FontSizesValues.S,
  color: Colors.brand,
};

const SmallLink = {
  fontFamily: Fonts.OPEN_SANS_SEMIBOLD,
  fontSize: FontSizesValues.XS,
  color: Colors.brand,
};

const Typography = {
  Catchphrase,
  Headline,
  MediumHeadline,
  CardHeader,
  BodyText,
  AdditionalInformation,
  Link,
  SmallLink,
};

const Theme = {
  colors: Colors,
  fonts: Fonts,
  typography: Typography,
  space: Space,
  spaceNumbers: SpaceValues,
  fontSizes: FontSizes,
  fontSizesNumbers: FontSizesValues,
};

export default Theme;
