/**
 * Contains Regular Expressions
 */

/**
 * Origin: https://stackoverflow.com/a/742588
 */
export const emailRegExp = RegExp('^[^@\\s]+@[^@\\s\\.]+\\.[^@\\.\\s]+$');
export const firstNameRegExp = RegExp('\\w+');
export const passwordRegExp = RegExp('.{8,}');
