import styled from 'styled-components';
import {
  ColorProps,
  SpaceProps,
  TypographyProps,
  color,
  compose,
  space,
  typography,
} from 'styled-system';

export interface EQTextProps extends TypographyProps, SpaceProps, ColorProps {}

export default styled.div<EQTextProps>`
  font-family: ${({ theme }) => theme.fonts.OPEN_SANS_REGULAR};
  font-size: ${({ theme }) => theme.fontSizes.M};
  color: ${({ theme }) => theme.colors.brand};
  ${compose(typography, space, color)}
`;
