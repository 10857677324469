import firebase from 'firebase/app';
import React, { useContext, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { VoucherContext } from '../App';
import EQButton from '../components/EQButton';
import EQText from '../components/EQText';
import EQTextInput from '../components/EQTextInput';
import Span from '../components/Span';
import Colors from '../theme/Colors';
import { errorMessages } from '../utils/errorMessages';
import { ON_DESKTOP } from '../utils/media';
import { emailRegExp } from '../utils/regex';
import { backendErrors } from '../utils/types';

interface LoginFormData {
  email: string;
  password: string;
}

const Container = styled.div`
  padding: ${({ theme }) => theme.space.L};

  background-color: ${({ theme }) => theme.colors.silver};
  max-width: 640px;

  ${ON_DESKTOP} {
    border-radius: ${({ theme }) => theme.space.S};
    width: 100%;
  }

  & a {
    font-weight: bold;
  }
`;

const Headline = styled(EQText)`
  ${({ theme }) => theme.typography.MediumHeadline};
  margin-top: ${({ theme }) => theme.space.M};
  margin-bottom: ${({ theme }) => theme.space.S};
`;

const ErrorMessage = styled(EQText)`
  color: #f7354b;
  font-size: 14px;
`;

const Login = () => {
  const { errors, setError, register, handleSubmit, formState } = useForm({
    mode: 'onSubmit',
  });
  const { voucher } = useContext(VoucherContext);
  const passwordRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const redeemVoucherCode = useMemo(
    () =>
      firebase
        .app()
        .functions('europe-west1')
        .httpsCallable('redeemVoucherCode'),
    [],
  );

  const onSubmit = async (data: LoginFormData) => {
    try {
      // Login user
      await firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password);

      // Redeem Code
      const response = await redeemVoucherCode({ code: voucher.code });
      console.log({ response });
      if (response.data.error) {
        setError('code', {
          type: 'manual',
          message: errorMessages[response.data.error],
        });
      } else {
        history.push('/activated');
      }
    } catch (error) {
      const e = backendErrors[error.code];
      setError(e[0], { type: 'manual', message: e[1] });
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EQText>
          To activate your {voucher.typeString} subscription, please log in or
          create a new Equalista account so that we know which account we need
          to link to the subscription. Of course, this subscription will not
          renew automatically.
        </EQText>
        <Headline>Log in</Headline>
        <EQText color={'darkGrey'} fontSize={'S'} pb="M">
          New to Equalista?
          <Span pl={'XS'}>
            <a href="/#/register">SIGN UP FOR FREE</a>
          </Span>
        </EQText>
        <EQTextInput
          error={errors.email?.message}
          name="email"
          onBlur={() => passwordRef.current?.focus()}
          placeholder="Email"
          ref={register({
            pattern: {
              value: emailRegExp,
              message: 'Oops, please enter a valid email address.',
            },
          })}
          type="email"
        />

        <EQTextInput
          error={errors.password?.message}
          name="password"
          placeholder="Password"
          ref={(el) => {
            register(el);
            // @ts-ignore
            passwordRef.current = el;
          }}
          type="password"
        />

        <ErrorMessage>{errors?.code?.message}</ErrorMessage>
        <EQText fontSize="S" py="S" textAlign="right">
          <a href="/#/resetPassword" style={{ color: Colors.eqBlue }}>
            FORGOT PASSWORD?
          </a>
        </EQText>
        <EQButton color="eqBerry" isLoading={formState.isSubmitting}>
          Log in
        </EQButton>
      </form>
    </Container>
  );
};

export default Login;
