import firebase from 'firebase/app';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import EQButton from '../components/EQButton';
import EQText from '../components/EQText';
import EQTextInput from '../components/EQTextInput';
import useRequiresVoucherCode from '../hooks/useRequiresVoucherCode';
import { ON_DESKTOP } from '../utils/media';
import { emailRegExp } from '../utils/regex';
import { backendErrors } from '../utils/types';

interface LoginFormData {
  email: string;
}

const Container = styled.div`
  padding: ${({ theme }) => theme.space.L};
  background-color: ${({ theme }) => theme.colors.silver};
  height: 100%;
  border-radius: ${({ theme }) => theme.space.S};
  max-width: 640px;

  ${ON_DESKTOP} {
    border-radius: ${({ theme }) => theme.space.S};
    width: 100%;
  }

  & a {
    font-weight: bold;
  }
`;

const ResetPassword = () => {
  const { errors, setError, register, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
  });
  const watchEmail = watch('email');
  const history = useHistory();
  useRequiresVoucherCode();

  const onSubmit = (data: LoginFormData) => {
    firebase
      .auth()
      .sendPasswordResetEmail(data.email)
      .then(function () {
        history.push('/login');
      })
      .catch(function (error) {
        const e = backendErrors[error.code];
        setError(e[0], { type: 'manual', message: e[1] });
      });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EQText
          fontSize={'L'}
          paddingBottom={20}
          paddingLeft={5}
          paddingTop={15}>
          Forgot your password?
        </EQText>
        <EQText
          fontSize={'S'}
          paddingBottom={20}
          paddingLeft={5}
          paddingTop={15}>
          No worries, just enter your registered email address and we will send
          a reset link to your inbox straight away.
        </EQText>

        <EQTextInput
          error={errors.email?.message}
          name="email"
          placeholder="Email"
          ref={register({
            pattern: {
              value: emailRegExp,
              message: 'Oops, please enter a valid email address.',
            },
          })}
          type="text"
          value={watchEmail}
        />
        <EQButton>send</EQButton>
      </form>
    </Container>
  );
};

export default ResetPassword;
