import 'firebase/functions';

import firebase from 'firebase/app';
import React, { useContext, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { VoucherContext } from '../App';
import EQButton from '../components/EQButton';
import EQText from '../components/EQText';
import EQTextInput from '../components/EQTextInput';
import Span from '../components/Span';
import redeemIllustration from '../images/RedeemIllustration.svg';
import { errorMessages } from '../utils/errorMessages';
import { ON_DESKTOP, ON_PHONE } from '../utils/media';
import { SubscriptionType, SubscriptionTypeString } from '../utils/types';

interface LoginFormData {
  code: string;
}

const Container = styled.div`
  padding: ${({ theme }) => theme.space.L};
  border-radius: ${({ theme }) => theme.space.S};
  background-color: ${({ theme }) => theme.colors.silver};
  max-width: 640px;

  ${ON_DESKTOP} {
    width: 100%;
  }

  ${ON_PHONE} {
    margin: ${({ theme }) => theme.space.M};
  }
`;

const Headline = styled(EQText)`
  ${({ theme }) => theme.typography.MediumHeadline};
  margin-top: ${({ theme }) => theme.space.M};
  margin-bottom: ${({ theme }) => theme.space.S};
`;

const StyledIllustration = styled.img`
  max-width: 100%;
`;

const SubmitButtonWrapper = styled.div`
  margin: ${({ theme }) => theme.space.S} auto;
  justify-content: center;
  display: flex;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.space.M};

  & a {
    font-weight: bold;
  }
`;

const RedeemCode = () => {
  const { errors, register, handleSubmit, setError, formState } = useForm({
    mode: 'onSubmit',
  });
  const { setVoucher } = useContext(VoucherContext);
  const history = useHistory();

  const checkVoucherCode = useMemo(
    () =>
      firebase
        .app()
        .functions('europe-west1')
        .httpsCallable('checkVoucherCode'),
    [],
  );

  const onSubmit = async (data: LoginFormData) => {
    try {
      const response = await checkVoucherCode({
        code: data.code.toUpperCase(),
      });
      if (response.data.error) {
        setError('code', {
          type: 'manual',
          message: errorMessages[response.data.error],
        });
      } else {
        setVoucher({
          type: response.data.type,
          code: data.code.toUpperCase(),
          typeString:
            SubscriptionTypeString[response.data.type as SubscriptionType],
        });

        history.push('/register');
      }
    } catch (error) {
      setError('code', {
        type: 'manual',
        message: error.toString(),
      });
    }
  };

  return (
    <div>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Headline>Redeem your Equalista Plus Code</Headline>
          <EQTextInput
            error={errors.code?.message}
            inputStyle={{ textTransform: 'uppercase' }}
            name="code"
            placeholder="Code"
            ref={register({
              required: 'Oops, please enter a valid code.',
            })}
            type="text"
          />
          <SubmitButtonWrapper>
            <EQButton
              color="eqGreen"
              isLoading={formState.isSubmitting}
              size="small"
              width={150}>
              Submit
            </EQButton>
          </SubmitButtonWrapper>
          <FooterWrapper>
            <EQText color={'darkGrey'} fontSize={'S'}>
              Have questions?
              <Span pl={'XXS'}>
                <a
                  href="https://equalista.com/help/"
                  rel="noreferrer"
                  target="_blank">
                  HELP
                </a>
              </Span>
            </EQText>
          </FooterWrapper>
        </form>
      </Container>
      <StyledIllustration src={redeemIllustration} />
    </div>
  );
};

export default RedeemCode;
