import styled from "styled-components";
import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  border,
  color,
  compose,
  flexbox,
  layout,
  space,
} from "styled-system";

export interface WrapperProps
  extends ColorProps,
    SpaceProps,
    LayoutProps,
    FlexboxProps,
    BorderProps {}

export default styled.div<WrapperProps>`
  ${compose(color, space, flexbox, border, layout)}
`;
