// All of the colors in the current design system

const BaseColors = {
  // Primary
  eqBlue: '#000078',
  eqGreen: '#7FB812',
  eqWhite: '#FCFCFC',
  white: '#FFFFFF', // Usually shouldn't be used
  radishRed: '#F7354B',
  eqBerry: '#E41884',
  // Tints
  eqLightGreen: '#E3EECD',
  // Neutrals
  silver: '#ECECEF',
  lightGrey: '#CDCDD2',
  midGrey: '#ACAFBA',
  darkGrey: '#898C96',
  // Transparent Overlays
  whiteOp88: '#ffffffe0',
  blackOp40: '#00000066',
};

const NamedColors = {
  // General
  brand: BaseColors.eqBlue,
  background: BaseColors.eqWhite,
  error: BaseColors.radishRed,
  placeholder: BaseColors.darkGrey,
  disabledButtonBackground: BaseColors.lightGrey,
  valid: BaseColors.eqGreen,
  // Text
  neutralText: BaseColors.darkGrey,
  placeholderText: BaseColors.darkGrey,
  // Cards
  card: BaseColors.silver,
  separator: BaseColors.lightGrey,
  // Navigation
  tabbarIconActive: BaseColors.eqBlue,
  tabbarLabelActive: BaseColors.eqBlue,
  tabbarIconInactive: BaseColors.midGrey,
  tabbarLabelInactive: BaseColors.midGrey,
  dotActive: BaseColors.eqBlue,
  dotInactive: BaseColors.lightGrey,
};

const AllColors = {
  ...BaseColors,
  ...NamedColors,
};

export default AllColors;
