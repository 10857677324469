import 'firebase/auth';

import firebase from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

import App from './App';

const firebaseConfig = {
  apiKey: 'AIzaSyDZFa8LN_BmRblxD9WrUSN_f9wqqghW0Yc',
  authDomain: 'equalista-app.firebaseapp.com',
  databaseURL: 'https://equalista-app.firebaseio.com',
  projectId: 'equalista-app',
  storageBucket: 'equalista-app.appspot.com',
  messagingSenderId: '170073724606',
  appId: '1:170073724606:web:bb3055368ae3641fb8b0ef',
  measurementId: 'G-L78KNF2X5E',
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
