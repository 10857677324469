export enum SubscriptionType {
  'LIFETIME' = 'lifetime',
  'ONE_YEAR' = '1_YEAR',
  'ONE_MONTH' = '1_MONTH',
  'THREE_MONTHS' = '3_MONTHS',
  'SIX_MONTHS' = '6_MONTHS',
}

export interface Voucher {
  type?: SubscriptionType;
  code: string;
  typeString?: string;
}

export const SubscriptionTypeString: Record<SubscriptionType, string> = {
  [SubscriptionType.LIFETIME]: 'lifetime',
  [SubscriptionType.ONE_YEAR]: 'one year',
  [SubscriptionType.ONE_MONTH]: 'one month',
  [SubscriptionType.THREE_MONTHS]: 'three months',
  [SubscriptionType.SIX_MONTHS]: 'six months',
};

export const backendErrors: Record<string, [string, string]> = {
  'auth/email-already-in-use': [
    'email',
    'Oops, this email address is already registered.',
  ],
  'auth/weak-password': [
    'password',
    'Oops, please enter at least 8 characters.',
  ],
  'auth/invalid-email': ['email', 'Oops, please enter a valid email address.'],
  'auth/invalid-firstName': ['firstName', 'Oops, please enter a valid name.'],
  'auth/terms-not-accepted': [
    'privacyService',
    'Oops, please check and accept our Terms of Service and Privacy Policy.',
  ],
  'auth/user-not-found': [
    'email',
    'There is no account registered for this email adress.',
  ],
  'auth/wrong-password': ['password', 'Wrong password. Please try again.'],
};
