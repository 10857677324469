import React, { useState } from "react";
import styled from "styled-components";
import { compose, layout, LayoutProps, space, SpaceProps } from "styled-system";
import { ReactComponent as CheckMark } from "../../icons/CheckMark.svg";
import { ReactComponent as ExclamationMark } from "../../icons/ExclamationMark.svg";
import { ReactComponent as IconEyeActive } from "../../icons/EyeActive.svg";
import { ReactComponent as IconEyeInactive } from "../../icons/EyeInactive.svg";
import { ReactComponent as MailIcon } from "../../icons/Mail.svg";
import { ReactComponent as ProfileIcon } from "../../icons/Name.svg";
import { ReactComponent as PasswordIcon } from "../../icons/Password.svg";

interface EQTextInputProps extends LayoutProps, SpaceProps {
  onChange?: any;
  onBlur?: any;
  error?: string;
  iconLeft?: React.FC;
  iconRight?: React.FC;
  label?: string;
  style?: object;
  validRegExp?: RegExp;
  value?: string;
  type: string;
  name: string;
  inputStyle?: object;
  placeholder?: string;
}

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.space.XXXS};
  ${compose(space, layout)};
`;

const InnerContainer = styled.div<Pick<EQTextInputProps, "error">>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.space.M};
  border-radius: ${({ theme }) => theme.space.XXS};

  /* Error Border */
  border: 1px solid
    ${({ error, theme }) => (!error ? "transparent" : theme.colors.error)};
`;

const StyledInput = styled.input`
  flex: 1;
  color: ${({ theme }) => theme.colors.brand};
  font-family: ${({ theme }) => theme.fonts.OPEN_SANS_REGULAR};
  font-size: ${({ theme }) => theme.fontSizes.M};
  padding-left: ${({ theme }) => theme.space.XS};
  /* type: "text" | "password"; */
  border: none;
  background-color: ${({ theme }) => theme.colors.eqWhite};
  &:focus {
    outline: none;
  }
`;

const IconWrapper = styled.div`
  height: 24px;
  cursor: pointer;
`;

const ErrorMessage = styled.div<{ hide?: boolean }>`
  margin-horizontal: ${({ theme }) => theme.space.XXXS};
  margin-top: ${({ theme }) => theme.space.XXXS};
  margin-bottom: ${({ theme }) => theme.space.XS};
  color: ${({ theme }) => theme.colors.error};
  font-family: ${({ theme }) => theme.fonts.OPEN_SANS_REGULAR};
  font-size: ${({ theme }) => theme.fontSizes.S};
  align-self: flex-start;

  ${(props) => props.hide && "opacity:0; user-select:none; cursor:cursor;"}
`;

const EQTextInput = React.forwardRef(
  (
    {
      onChange,
      onBlur,
      error,
      style = {},
      placeholder,
      value,
      type,
      name,
      inputStyle = {},
      ...props
    }: EQTextInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [readable, setReadable] = useState<boolean>(false);
    const onIconPress = () => {
      setReadable((current) => !current);
    };

    const icons: Record<string, React.FC> = {
      firstName: ProfileIcon,
      email: MailIcon,
      password: PasswordIcon,
    };

    const LeftIcon = icons[name];

    const IconEye = (props: any) => {
      return (
        <IconWrapper onClick={props.onClick}>
          {props.active ? <IconEyeActive /> : <IconEyeInactive />}
        </IconWrapper>
      );
    };

    return (
      <OuterContainer style={style}>
        <InnerContainer error={error}>
          {LeftIcon && <LeftIcon />}
          <StyledInput
            style={inputStyle}
            name={name}
            ref={ref}
            defaultValue={value}
            placeholder={placeholder}
            onBlur={onBlur}
            type={readable ? "text" : type}
          />

          {!!error ? (
            <ExclamationMark />
          ) : type === "password" ? (
            <IconEye active={readable} onClick={onIconPress} />
          ) : value !== undefined && value !== "" ? (
            <CheckMark />
          ) : null}
        </InnerContainer>
        {!!error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <ErrorMessage hide>dummy message</ErrorMessage>
        )}
      </OuterContainer>
    );
  }
);

export default EQTextInput;
